import React, { ReactElement } from 'react'
import { useForm, FieldError } from "react-hook-form";
import { HTTPMethod } from 'utils/HTTPUtils';

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}

const ContactForm = (): JSX.Element => {
  const contactFormEndpoint: string = process.env.NEXT_PUBLIC_GETFORM_HELP_CENTRE_ENDPOINT ?? "";
  const { register, handleSubmit, formState: {isSubmitting, isSubmitSuccessful, errors } } = useForm<FormValues>();

  const onSubmit =  handleSubmit((data) => {
    const formData = new FormData();
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('email', data.email);
    formData.append('message', data.message);

    fetch(
      contactFormEndpoint, {
        method: HTTPMethod.POST,
        headers: { Accept: "application/json" },
        body: formData
      },
      )
      .then(function (/* response */) {
        // TODO: Handle response
      })
      .catch(function (/* error */) {
        // TODO: Handle Error
      });
  });

  const SuccessNotification = (): ReactElement => 
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="shrink-0">
          <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
          </svg>
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-green-800">
            Your message has been sent!
          </h3>
          <div className="mt-2 text-sm text-green-700">
            <p>
              Thank you for your message, we will get back to you as soon as possible.
            </p>
          </div>
        </div>
      </div>
    </div>;

  const getInputClassName = (fieldError: FieldError|undefined) => fieldError ?
    "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm focus:ring-primary focus:border-primary" :
    "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm focus:ring-black focus:border-black"; 

  const getTextareaClassName = (fieldError: FieldError|undefined) => fieldError ?
    "shadow-sm mt-1 block w-full sm:text-sm border border-gray-300 rounded-md py-2 px-3 focus:ring-primary focus:border-primary" :
    "shadow-sm mt-1 block w-full sm:text-sm border border-gray-300 rounded-md py-2 px-3 focus:ring-black focus:border-black";

  return (
    <form autoComplete="off" onSubmit={onSubmit} noValidate>
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">

          {isSubmitSuccessful ?  
            <SuccessNotification /> :
            <div>
              <div>
                <h3 className="italic">Please tell us how we can help.</h3>
              </div>
              <div className="grid grid-cols-6 gap-6 mt-4">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="first-name" className="">First Name</label>
                  <input 
                    type="text" 
                    id="firstName" 
                    autoComplete="given-name" 
                    className={getInputClassName(errors.firstName)}
                    placeholder="First Name"
                    {...register("firstName", {
                      required: "Please enter your first name"
                     })}
                    />
                    { errors.firstName && <p className="block text-sm text-primary mt-2">{ errors.firstName.message }</p>}
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="last-name" className="">Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    autoComplete="family-name"
                    className={getInputClassName(errors.lastName)}
                    placeholder="Last Name" 
                    {...register("lastName", {
                      required: "Please enter your last name"
                    })}
                    />
                    { errors.lastName && <p className="block text-sm text-primary mt-2"> { errors.lastName.message } </p>}
                </div>

                <div className="col-span-6 sm:col-span-4">
                  <label htmlFor="email" className="">Email Address</label>
                  <input
                   type="email"
                   id="email"
                   autoComplete="off"
                   className={getInputClassName(errors.email)}
                   placeholder="Email Address"
                  {...register("email", { 
                    required: "Please enter your email address",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format"
                    } 
                  })}
                   />
                  { errors.email && <p className="block text-sm text-red-500 mt-2">{ errors.email.message }</p>}

                </div>

                <div className="col-span-6">
                  <label htmlFor="message" className="">
                    Message
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="message"
                      rows={3}
                      className={getTextareaClassName(errors.message)}
                      placeholder="Message"
                      {...register("message", {
                        required: "Please enter your message",
                      })}
                      >
                    </textarea>
                    { errors.message && <p className="block text-sm text-red-500 mt-2">{ errors.message.message } </p>}
                  </div>
                </div>
            </div>
          </div>}

        </div>
        { !isSubmitSuccessful ?  
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button 
                type="submit"
                disabled={isSubmitting}
                className="bg-black border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-black/90">
                {isSubmitting ? "Sending.." : "Send"}
              </button>
          </div>:
          null } 
      </div>
    </form>
  );
};

export default ContactForm;