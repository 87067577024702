import { useRouter } from 'next/router';
import Link from 'next/link';
import { appRoutes } from 'constants/appRoutes';
import { reportUserClickedOnEvent } from 'utils/analytics/segment';
import { ReactElement } from 'react';

type Props = {
  isTransparent?:  boolean;
}

const VisitorHeader = ({ isTransparent = false }: Props): ReactElement => {
  const { pathname } = useRouter();

  return (
    <nav className={isTransparent ? styles.transparent.root : styles.default.root}>
      <div className={styles.default.container}>
          {/* Left - Logo */}
          <div className={styles.default.left}>
            <Link href={appRoutes.HOME} className={styles.default.logoHref}>

              <img
                className={styles.default.logoImg}
                src={isTransparent ? "/images/logotype-black.svg" : "/images/logotype-black.svg"}
                alt="Nexton Capital"
              />

            </Link>
          </div>

          {/* Right */}
          <div className={styles.default.right}>
            <Link
              href={appRoutes.HELP_CENTRE}
              className={isTransparent ? styles.transparent.ctaHref : styles.default.ctaHref}
              onClick={() => reportUserClickedOnEvent('Get In Touch', 'Homepage Header')}>
                Get In Touch
            </Link>
          </div>
      </div>
    </nav>
  );
}

export default VisitorHeader

const styles = {
  default: {
    root: 'fixed bg-white top-0 w-full z-20 border-b border-gray-200 shadow-sm',
    container: 'max-w-7xl mx-auto flex justify-between h-16 px-4 sm:px-6 lg:px-8',
    left: 'flex',
    logoHref: 'flex items-center',
    logoImg: 'block h-8 w-auto',
    right: 'flex items-center',
    ctaHref: 'block w-full py-1.5 px-4 text-center bg-white border border-primary rounded-full text-base font-medium text-primary hover:bg-primary hover:text-white sm:inline-block sm:w-auto transition duration-200 ease-in-out'
  },
  transparent: {
    root: 'fixed bg-transparent top-0 w-full z-20 border-b border-transparent',
    ctaHref: 'block w-full py-1.5 px-4 text-center bg-transparent border border-white rounded-md text-base font-medium text-white hover:bg-black hover:text-white sm:inline-block sm:w-auto transition duration-200 ease-in-out'
  }
}